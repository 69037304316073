<template>
    <div>
        <AccountDetailsForm :email="email" :accountId="userId" :isSubscribed="isSubscribed" :personInfo="personInfo" :accountAddress="accountAddress" />
        <div class="px-2">
            <v-container>
                <!-- Delete Account User -->
                <v-row class="mb-0 px-4" >
                    <v-col cols="12" class="px-0 pb-0 pt-3">
                        <span class="display-1">Account verwijderen</span>
                    </v-col>
                    <v-col cols="12" class="ma-0 pt-3 px-0">
                        Indien u besluit om uw account te verwijderen kunt u niet meer inloggen. U zult dan onder meer geen toegang meer hebben tot uw persoonlijke foto's of inzicht hebben in uw meegereden aantal kilometers. Het is niet mogelijk om deze actie ongedaan te maken.
                    </v-col>
                    <v-col  cols="12" class="pt-3 px-0">
                        <v-btn block @click="gotoDeleteAccount()">
                        Account verwijderen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AccountDetailsForm from "@/components/dashboard/AccountDetailsForm";
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'Account',
    components: 
    {
        AccountDetailsForm
    },
    data: () => ({
        email: '',
        personInfo: { foodRestrictions: []},
        accountAddress: {},
        isSubscribed: null
    }),
    computed: {
        ...mapState('authorizedModule', ['userId']),
    },
    mixins: [DataMixin, RouteMixin, MetaTagsMixin],
    metaInfo() {
        return {
        title: '| Accountgegevens',
        meta: [
            this.getKeywords(`Accountgegevens`),
            this.getMetaDescription(`Mijn Accountgegevens`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Mijn Accountgegevens`, `Mijn Accountgegevens`, '', ''),
        ]
        };
    },
    watch: {
        userId: {
            immediate: true,
            handler() {
                this.getUserInfo();
            }
        }
    },
    beforeCreate(){
        this.$store.dispatch('menuHelper/getFoodRestrictions');
        this.$store.dispatch('menuHelper/getCountries');
    },
    mounted() {
        this.$store.commit('setFooterVisibility', true);
    },
    methods: 
    {
        getUserInfo()
        {
            if (!this.userId) return;

            this.$store.commit('setFooterVisibility', true);
            // get account
            this.$store.dispatch('accountModule/getAccountOwnerBasePersoninfo', this.userId)
            .then((data) => {
                this.personInfo = data;
            });

            this.$store.dispatch('accountModule/getAddressFromAccountowner',  this.userId)
            .then((data) => {
                this.accountAddress = data;
            });

            this.$store.dispatch('accountModule/getEmailAddressFromAccountOwner', this.userId)
            .then(email => {
                this.email = email;
            })

            this.$store.dispatch('newsletterModule/accountIsSubscribed', this.userId)
            .then(data => {
                this.isSubscribed = data;
            })
        }
    }
}
</script>
<style scoped>
.header
{
    display: inline-flex;
}

.edit-icon
{
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.v-input
{
    height: 25px;
}
</style>